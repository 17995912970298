import { useEffect } from "react";
import { Credentials } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";

import { useAuth0 } from "@auth0/auth0-react";

import { getConfig, setIdToken } from './config';

const { openidProvider } = getConfig();

const NavBar = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  
  useEffect(() => {
    const fetchAccessToken = async () => {
      const idtoken = await getIdTokenClaims();
      setIdToken(idtoken);
      
      await Credentials.set(
				{ provider: openidProvider, token: idtoken.__raw, user: { name: idtoken.email }, expires_at: 3600 * 1000 + new Date().getTime() },
				'federation'
			);
			const currentUser = await Auth.currentAuthenticatedUser();
			console.log(
				'signIn',
				currentUser.name
			);
    }
    if (isAuthenticated) {
      fetchAccessToken();
    }
    if (!isAuthenticated){
      loginWithRedirect()
    }
    

  }, [isAuthenticated,getIdTokenClaims,loginWithRedirect])

  return null
};

export default NavBar;
