

export function getConfig() {
  return {
    domain: process.env.REACT_APP_DOMAIN,
    clientId: process.env.REACT_APP_CLIENT_ID,
    cdn: process.env.REACT_APP_CDN,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    openidProvider: process.env.REACT_APP_OPENID_PROVIDER,
    backendURL : process.env.REACT_APP_API_ENDPOINT,
    backendAudience : process.env.REACT_APP_API_AUDIENCE,
    emailSupport : process.env.REACT_APP_EMAIL_SUPPORT,
  };
}
var idToken;
export function getIdToken() {
  return idToken;}

  export function setIdToken(value) {
  idToken = value;}
