import React, {useState, useRef, useEffect} from 'react';
import { Credentials } from "@aws-amplify/core";
import "@cloudscape-design/global-styles/index.css"
import './App.css';
import AppLayout from "@cloudscape-design/components/app-layout";
import FormField from "@cloudscape-design/components/form-field";
import Alert from "@cloudscape-design/components/alert";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import TokenGroup from "@cloudscape-design/components/token-group";
import SpaceBetween from "@cloudscape-design/components/space-between";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import {Storage} from 'aws-amplify';
import HelpNavigation from './HelpNavigation';
import NewNavigation from './NewNavigation';
import Inbetween from './Inbetween';
import SideNavigation from '@cloudscape-design/components/side-navigation';
import {useNavigate} from 'react-router-dom';
import { getConfig} from "./config";
import { useAuth0 } from "@auth0/auth0-react";
import { Buffer } from 'buffer';



Storage.configure({
    region: process.env.REACT_APP_AWS_REGION,
    bucket: process.env.REACT_APP_INPUT_BUCKET
    
});

const { openidProvider } = getConfig();

const appLayoutLabels = {
    navigation: 'Side navigation',
    navigationToggle: 'Open side navigation',
    navigationClose: 'Close side navigation',
    notifications: 'Notifications',
    tools: 'Help panel',
    toolsToggle: 'Open help panel',
    toolsClose: 'Close help panel'
};
const ServiceNavigation = () => {
    const { cdn, emailSupport } = getConfig();
    let navigate = useNavigate();

    function onFollowHandler(event) {
        if (!event.detail.external) {
            event.preventDefault();
            navigate(event.detail.href);
        }
    }

    return (
        <SideNavigation
            activeHref={'/'}
            header={{href:"/", text: "Object Upload"}}
            onFollow={onFollowHandler}
            items={[
                {type: "link", text: "Download the Template (.xlsx)", href: `${cdn}/template/Shelters Template - V4 (1).xlsx`, external: true},
                {type: "link", text: "Contact Us", href: `mailto:${emailSupport}`, external: true},
                {type: "divider"},
                {
                    type: "link",
                    text: "Our Products",
                    href: "https://www.boehringer-ingelheim.com/animal-health/products",
                    external: true
                }
            ]}
        />
    );
}

function formatBytes(a, b = 2, k = 1024) {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 === a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

function toConvertInBytes(filesize){

    if (filesize.includes('KB')){
        const filesizeinbytes = parseInt(filesize) * 1024;
        return filesizeinbytes;
        }
    else if (filesize.includes('MB')){
        const filesizeinbytes = parseInt(filesize) * 1024 * 1024;
        return filesizeinbytes;
        }
    else {
        const filesizeinbytes = filesize
        return filesizeinbytes;
        }   
}

const Content = () => {

    const { user, logout } = useAuth0();
  
    const [expirydate, setExpiryDate] = useState(new Date('2099-09-09T12:00:02.448804522Z'))
        const {
           isAuthenticated,
           getIdTokenClaims,
           loginWithRedirect
         } = useAuth0();
    
    useEffect(() => {
        const fetchAccessToken = async () => {
            const idtoken = await getIdTokenClaims();
             
            await Credentials.set(
                       { provider: openidProvider, token: idtoken.__raw, user: { name: idtoken.email }, expires_at:300 * 1000 + new Date().getTime() },
                       'federation'
                   );
       
            const expirytime = Credentials['_credentials']['expiration']
            setExpiryDate(expirytime)
           
           }
        if (isAuthenticated) {
             fetchAccessToken();//
           }

        if (!isAuthenticated){
            loginWithRedirect()
          }
           
        }, [isAuthenticated,getIdTokenClaims,loginWithRedirect])
       
        const currentTime = Date.now()
        
        
        if (currentTime < expirydate) {
            console.log(' ')
        }
        else{
            logout({
                logoutParams:{
                    returnTo: window.location.origin
                }          
            });
        }
   

    const hiddenFileInput = useRef(null);
    const [visibleErrorAlert,setVisibleErrorAlert] = useState(false)
    const [visibleSuccessAlert,setVisibleSuccessAlert] = useState(false)
    const [visibleWarningAlert,setVisibleWarningAlert] = useState(false)
    const [wrongTypeAlert,setWrongTypeAlert] = useState(false)
    const [uploadList, setUploadList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [historyCount, setHistoryCount] = useState(0);
    const [isSubmit,setIsSubmit] = useState(false)
    const handleClick = () => {
        hiddenFileInput.current.value = ""; // This avoids errors when selecting the same files multiple times
        hiddenFileInput.current.click();
    };


const handleChange = e => {
    e.preventDefault();
    let i,selectedFile, tempUploadList = [], tempFileList = [];
    selectedFile = e.target.files

    for (i = 0; i < selectedFile.length; i++) {
      const file = e.target.files[i];

      const currentIndex = i;
      const reader = new FileReader()
        
      reader.onloadend = (e) => {

        const arrBuffer = e.target.result;
  
        
        const buffer = Buffer.from(arrBuffer);
  
        // Define the file signatures for CSV, XLS, and XLSX
        const signatures = {
          csv: ['text/csv', 'application/vnd.ms-excel'],
          xls: ['D0CF11E0A1B11AE1'], 
          xlsx: ['504b030414000600'] 
        };
  
    //     // Check the file signature
    const fileSignature = buffer.toString('hex', 0, 8);
    const isCsv = signatures.csv.includes(file.type);
    const isXls = signatures.xls.some(sig => fileSignature.startsWith(sig.toLowerCase()));
    const isXlsx = signatures.xlsx.some(sig => fileSignature.startsWith(sig.toLowerCase()));

    const fileExtension = file.name.split('.').pop().toLowerCase();
    const isPermittedExtension = ['csv', 'xls', 'xlsx'].includes(fileExtension);
        if ((isCsv || isXls || isXlsx) && isPermittedExtension) {
            tempUploadList.push({
                                label: file.name,
                                labelTag: formatBytes(file.size),
                                description: 'File type: ' + file.type,
                                icon: 'file',
                                id: currentIndex
                            })
                                
                                  tempFileList.push(file);
              // Set the uploadList and fileList state variables here
              
        } else {
            setWrongTypeAlert(true);
                setTimeout(()=>{
                setWrongTypeAlert(false)
                },10000)
        }            
        setUploadList(tempUploadList);
        setFileList(tempFileList);
    }
    
        reader.readAsArrayBuffer(file);
    }
    
    }

  function progressBarFactory(fileObject) {
      let localHistory = historyList;
      const id = localHistory.length;
      localHistory.push({
          id: id,
          percentage: 0,
          filename: fileObject.name,
          filetype: fileObject.type,
          filesize: formatBytes(fileObject.size),
          status: 'in-progress'
      });
      setHistoryList(localHistory);
      return (progress) => {
          let tempHistory = historyList.slice();
          const percentage = Math.round((progress.loaded / progress.total) * 100);
          tempHistory[id].percentage = percentage;
          if (percentage === 100) {
              tempHistory[id]['status'] = 'success';
          }
          setHistoryList(tempHistory);
      };
  }

  const handleUpload = async () => {
    
      if (uploadList.length === 0) {
        
          setVisibleErrorAlert(true)
          setTimeout(()=>{
            setVisibleErrorAlert(false)
        },10000)
      } else {
            let userIdentification = user.sub.replace("email|",'') +'/'+ user.nickname +'/'+ user.email;
            
          let i,  uploadCompleted = [];
          for (i = 0; i < uploadList.length; i++) {
              // If the user has removed some items from the Upload list, we need to correctly reference the file
              const id = uploadList[i].id;
              console.log(uploadList)
              console.log(fileList)
              const filesize = formatBytes(fileList[id].size);
          
             const filesizeinb = toConvertInBytes(filesize);
                  
              if (filesizeinb >= 10000000){
                  setVisibleWarningAlert(true);
                  setTimeout(()=>{
                    setVisibleWarningAlert(false)
                },10000)
                  
                   } 
             else{
             
                let progressBar = []
              progressBar.push(progressBarFactory(fileList[id]));
              setHistoryCount(historyCount + 1);
              uploadCompleted.push(Storage.put(userIdentification + '/' + fileList[id].name, fileList[id], {
                      progressCallback: progressBar[i],
                      level: "protected"
                  }).then(result => {
                      // Trying to remove items from the upload list as they complete. Maybe not work correctly
                      // setUploadList(uploadList.filter(item => item.label !== result.key));
                     
                    })
                );  
                setVisibleSuccessAlert(true)         
       
        }
          // When you finish the loop, all items should be removed from the upload list
          
          Promise.all(uploadCompleted)
              .then(() => setUploadList([]));
              setIsSubmit(true)
          setTimeout(()=>{
              setIsSubmit(false)
          },10000)
            
      }
  }
}
  const handleDismiss = (itemIndex) => {
      setUploadList([
          ...uploadList.slice(0, itemIndex),
          ...uploadList.slice(itemIndex + 1)
      ]);
  };

  const List = ({list}) => (
      <>
          {list.map((item) => (
              <ProgressBar
                  key={item.id}
                  status={item.status}
                  value={item.percentage}
                  variant="standalone"
                  additionalInfo={item.filesize}
                  description={item.filetype}
                  label={item.filename}
              />
          ))}
      </>
  );
  return (
      <SpaceBetween size="xl">
          <Container 
              id="s3-upload-multiple-objects"
              header={
                  <Header variant="h2">
                      Upload your adoption data
                  </Header>
              }
          >
              {
                  <div>
                    {visibleErrorAlert && (
                        <Alert
                        onDismiss={() => setVisibleErrorAlert(false)}
                        visible={visibleErrorAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="error"
                        header="No file selected"
                    >
                        You must select the file that you want to upload.
                    </Alert>
                    )}

                    {visibleSuccessAlert && (
                        <Alert
                        onDismiss={() => setVisibleSuccessAlert(false)}
                        visible={visibleSuccessAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="success"
                        header="File Successfully Uploaded"
                    >
                        Your File is successfully uploaded.
                    </Alert>
                    )}

                    {visibleWarningAlert && (
                        <Alert
                        onDismiss={() => setVisibleWarningAlert(false)}
                        visible={visibleWarningAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="warning"
                        header="File Size Limit Exceed"
                    >
                        File size exceeded max limit! It should be less than 10mb
                    </Alert>
                    )}   

                    {wrongTypeAlert && (
                        <Alert
                        onDismiss={() => setWrongTypeAlert(false)}
                        visible={wrongTypeAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="warning"
                        header="File Type is not correct"
                    >
                        Invalid file type. Only CSV, XLS, and XLSX files are allowed.
                    </Alert>
                    )}                     

                      <FormField
                          label='Object Upload'
                          description='Select the file[s] that you want to upload (.csv, .xls, .xlsx file extensions only)'
                      />

                      <SpaceBetween direction="horizontal" size="xs">
                          <Button onClick={handleClick}
                                  iconAlign="left"
                                  iconName="upload"
                                  
                          >
                              Choose file[s]
                          </Button>
                          <input
                              type="file"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{display: 'none'}}
                              multiple
                          />
                          <Button variant="primary" onClick={handleUpload} disabled={isSubmit}>Upload</Button>
                      </SpaceBetween>

                      <TokenGroup
                          onDismiss={({detail: {itemIndex}}) => {
                              handleDismiss(itemIndex)
                          }}
                          items={uploadList}
                          alignment="vertical"
                          limit={10}
                      />
                  </div>
              }
          </Container>
          <Container
              id="history"
              header={
                  <Header variant="h2">
                      History
                  </Header>
              }
          >
              <List list={historyList}/>
          </Container>
          <Inbetween />
          
      </SpaceBetween>

  );
};

function App() {
   const [setNavigationOpen, handleToolsChange, navigationOpen, toolsOpen] = useState(true);

  return (      
              <>
                  
                  <div>
                  <NewNavigation />
                  </div>
                  
                  <AppLayout
                      content={<Content/>}
                      headerSelector='#navbar'
                      navigation={<ServiceNavigation/>}
                      navigationOpen={navigationOpen}
                      onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                      onToolsChange={({detail2}) => handleToolsChange(detail2.open)}
                      tools={<HelpNavigation/>}
                      toolsOpen={toolsOpen}
                      ariaLabels={appLayoutLabels}
                  />
              </>
          
  );
}

export default App;