import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import { getConfig} from "./config";

const Inbetween = () => {
    const { cdn } = getConfig();
    return(
    <Container
              id="instructions"
              header={
                  <Header variant="h2">
                      Instructions
                  </Header>
              }
          >
              <div>
              <p>Thank you for your participation in the Partners in Protection® free parasiticide program! Please use this page to upload your shelter's monthly Opt-In information.</p>
              <p>Currently, only files using the official template with the following file extensions are accepted: <b>.csv, .xls, .xlsx</b></p>
              <p>Examples of the template with properly formatted values are shown below. Minor variations in formatting are also accepted.</p>
              <a href={`${cdn}/images/shelter_template_example_2.png`} target="_blank" rel="noopener noreferrer">
                <img src={`${cdn}/images/shelter_template_example.png`} width="95%" alt="Example of Shelter Template filled out in Excel" />
              </a>
              <br /><br />
              <a href={`${cdn}/images/shelter_template_example_2.png`} target="_blank" rel="noopener noreferrer">
                <img src={`${cdn}/images/shelter_template_example_2.png`} width="95%" alt="Another example of Shelter Template filled out in Excel" />
              </a>
              <p>Please note that for the <code>spay_neuter</code> column, we will assume the values <code>S = SPAYED</code> and <code>N = NEUTERED</code>. We will also accept the values <code>YES</code> and <code>NO</code>.</p>
              </div>
          </Container>
    );
}

export default Inbetween ;