import SideNavigation from '@cloudscape-design/components/side-navigation';
import {useNavigate} from 'react-router-dom';
import { getConfig} from "./config";

const ServiceNavigation = () => {
    
    let navigate = useNavigate();
    const { cdn, emailSupport } = getConfig();
    function onFollowHandler(event) {
        if (!event.detail.external) {
            event.preventDefault();
            navigate(event.detail.href);
        }
    }

    return (
        <SideNavigation
            activeHref={'/'}
            header={{href:"/", text: "Object Upload"}}
            onFollow={onFollowHandler}
            items={[
                {type: "link", text: "Download the Template (.xlsx)", href: `${cdn}/template/Shelters Template - V4 (1).xlsx`, external: true},
                {type: "link", text: "Contact Us", href: `mailto:${emailSupport}`, external: true},
                {type: "divider"},
                {type: "link", text: "User's list", href: "/user-list"},
                {type: "divider"},
                {
                    type: "link",
                    text: "Our Products",
                    href: "https://www.boehringer-ingelheim.com/animal-health/products",
                    external: true
                }
            ]}
        />
    );
}

export default ServiceNavigation ;