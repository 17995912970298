import HelpPanel from "@cloudscape-design/components/help-panel";
import { getConfig } from "./config";

const HelpNavigation = () => {
    const {emailSupport} = getConfig();
    return (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Questions?
                    </h3>
                    <p>Reach us by email at <a href={`mailto:${emailSupport}`} target="_blank" rel="noopener noreferrer">{emailSupport}</a></p>
                </div>
            }
            header={<h2>Tips</h2>}
        >
        <div>
            <ul>
                <li>Upload only the following filetypes: .csv, .xls, .xlsx</li>
                <li>Use the official template provided and ensure all columns are present.</li>
                <li>Do not include extra columns.</li>
                <li>Ensure column names match the template exactly, e.g. <code>opt_in</code> and not <code>opt-in</code></li>
                <li>Do not include summary numbers or metadata at the bottom of your files.</li>
            </ul>
        </div>
        </HelpPanel>
    );
}

export default HelpNavigation;