import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import Upload from './Upload'
import NavBar from "./NavBar"
import Loading from "./Loading"
import { useAuth0 } from "@auth0/auth0-react";
import history from "./history";
import "./App.css";
import { getConfig} from "./config";
import Users from './Users'
import Detailuser from './detailuser';
import Register from './Register';
import UploadAdmin from './UploadAdmin'

const { identityPoolId } = getConfig();


Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  Auth: {
    identityPoolId: identityPoolId,
    region: process.env.REACT_APP_AWS_REGION
  },
});

const App = () => {
  const { isLoading, error,isAuthenticated,user} = useAuth0();
 
  let isAdmin = false
  if(isAuthenticated){
    let id =user.sub
  if(id.includes("oauth2")){
      isAdmin = true
    }  
  }


  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Router history={history}>
        <NavBar/>
        <Routes>
          
          {isAuthenticated &&
            <>
            
            {isAdmin &&
              <>
                <Route path="/" exact element={<UploadAdmin/>}/>
                <Route path="/user-list" element={<Users/>} />
                <Route path="/get-user/:id" element={<Detailuser />} />
                <Route path="/register" element={<Register />} />
              </>
            }
            <Route path="/" exact element={<Upload/>}/>
            </>
          }
            
        </Routes>
      </Router>
    </div>
  );
}
export default App;
