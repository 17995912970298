import React from 'react';
import ReactDOM from 'react-dom/client';
import "@cloudscape-design/global-styles/index.css"
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getConfig } from "./config";
import history from "./history";


const root = ReactDOM.createRoot(document.getElementById('root'));

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const {domain,clientId,backendAudience} = getConfig();



const providerConfig = {
  domain:domain,
  clientId: clientId,
  authorizationParams:{
    redirect_uri: window.location.origin,
    audience: backendAudience
  },
  onRedirectCallback,
};
root.render(
  <Auth0Provider {...providerConfig}>
    
    <App/>
    
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();