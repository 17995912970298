import React, {useState} from 'react';
import "@cloudscape-design/global-styles/index.css"
import './App.css';
import AppLayout from "@cloudscape-design/components/app-layout";
import FormField from "@cloudscape-design/components/form-field";
import Alert from "@cloudscape-design/components/alert";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Form from "@cloudscape-design/components/form";
import Input from "@cloudscape-design/components/input"
import { Link,useLocation} from "react-router-dom";
import HelpNavigation from './HelpNavigation';
import ServiceNavigation from './ServiceNavigation';
import NewNavigation from './NewNavigation';
import {useAuth0} from '@auth0/auth0-react'
import { getConfig} from "./config";

const appLayoutLabels = {
    navigation: 'Side navigation',
    navigationToggle: 'Open side navigation',
    navigationClose: 'Close side navigation',
    notifications: 'Notifications',
    tools: 'Help panel',
    toolsToggle: 'Open help panel',
    toolsClose: 'Close help panel'
};

const Content = () => {
  const location = useLocation();
  const queryParams =new URLSearchParams(location.search)
  const data = JSON.parse(queryParams.get('data')) || [];
  const [visibleSuccessAlert,setVisibleSuccessAlert] = useState(false)
  const [visibleErrorAlert,setVisibleErrorAlert] = useState(false)  
  const [emailAlreadyPresent,setEmailAlreadyPresent] = useState(false)
  const [activeItem,setActiveItem] = useState({
        nickname:'',
        email:''
      })

  const {getAccessTokenSilently} = useAuth0();
  const { backendURL } = getConfig();
  
     
  const Createuser = async () =>{
    if(data.some(d=>d.email === activeItem.email)){
      setEmailAlreadyPresent(true)
      setTimeout(()=>{
        setEmailAlreadyPresent(false)
    },10000)
    
  }
else{
  const token = await getAccessTokenSilently()
  fetch(`${backendURL}/create-user`,{
            method :'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

                },
            body: JSON.stringify({"nickname":activeItem.nickname,"email": activeItem.email})
            }).then(
        (response) => (response.json())
          ).then((response)=> {
        if (response.status === 'success') {
          alert("Message Sent.");
          this.resetForm()
        } else if(response.status === 'fail') {
          alert("Message failed to send.")
        } 
    setVisibleSuccessAlert(true)
    setTimeout(()=>{
      setVisibleSuccessAlert(false)
  },10000)
    })
}
  }
  const Submitvalues = (e) => {     
    e.preventDefault()
  if (activeItem.nickname === '' || activeItem.email === '' ){
      setVisibleErrorAlert(true)
      setTimeout(()=>{
        setVisibleErrorAlert(false)
    },10000)
      return
    } 
    Createuser()
    setActiveItem({nickname:'',email:''})
    }
    
    
    return (
        <form onSubmit={Submitvalues}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="s">
            <Link to={'/user-list'}><Button formAction="none" variant="link">
              Cancel
            </Button></Link>
            
            <Button variant="primary">Submit</Button>
          </SpaceBetween>
        }
        
      >
        <Container
          header={
            <Header variant="h2">
              Register New User
            </Header>
          }
        >
        {visibleSuccessAlert && (
                        <Alert
                        onDismiss={() => setVisibleSuccessAlert(false)}
                        visible={visibleSuccessAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="success"
                        header="User created"
                    >
                        User created succesfully.
                    </Alert>
                    )}  

        {visibleErrorAlert && (
                        <Alert
                        onDismiss={() => setVisibleErrorAlert(false)}
                        visible={visibleErrorAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="error"
                        header="Required feilds missing"
                    >
                        Please fill the Required feilds.
                    </Alert>
                    )}
                    {emailAlreadyPresent && (
                        <Alert
                        onDismiss={() => setEmailAlreadyPresent(false)}
                        visible={emailAlreadyPresent}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="error"
                        header="Email is already registered"
                    >
                      The email you are trying to register is already present.
                    </Alert>
                    )}

          <SpaceBetween direction="vertical" size="l">
            <FormField label="Nickname">
             <Input required inputMode="text" name="nickname"  onChange={({detail})=>setActiveItem({...activeItem,nickname:detail.value})} value={activeItem.nickname}/> 
            </FormField>
            <FormField label="Email">
            <Input required inputMode="email" name="email"  onChange={({detail})=>setActiveItem({...activeItem,email:detail.value})} value={activeItem.email}/>             
            </FormField>
            
          </SpaceBetween>
        </Container>
      </Form>
    </form>
    )
}  

function App() {
  const [setNavigationOpen, handleToolsChange, navigationOpen, toolsOpen] = useState(true);
  
  return (      
              <>
                  <div>
                  <NewNavigation />
                  </div>                 
                  <AppLayout
                      content={<Content/>}
                      headerSelector='#navbar'
                      navigation={<ServiceNavigation/>}
                      navigationOpen={navigationOpen}
                      onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                      onToolsChange={({detail2}) => handleToolsChange(detail2.open)}
                      tools={<HelpNavigation/>}
                      toolsOpen={toolsOpen}
                      ariaLabels={appLayoutLabels}
                  />

              </>          

  );
}

export default App;