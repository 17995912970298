import React, {useState,useEffect} from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import "@cloudscape-design/global-styles/index.css"
import './App.css';
import SpaceBetween from "@cloudscape-design/components/space-between";
import FormField from "@cloudscape-design/components/form-field";
import Container from "@cloudscape-design/components/container";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import AppLayout from "@cloudscape-design/components/app-layout";
import Form from "@cloudscape-design/components/form";
import Input from "@cloudscape-design/components/input"
import Alert from "@cloudscape-design/components/alert";
import { Link} from "react-router-dom";
import HelpNavigation from './HelpNavigation';
import ServiceNavigation from './ServiceNavigation';
import NewNavigation from './NewNavigation';
import {useAuth0} from '@auth0/auth0-react'
import { getConfig} from "./config";


const appLayoutLabels = {
    navigation: 'Side navigation',
    navigationToggle: 'Open side navigation',
    navigationClose: 'Close side navigation',
    notifications: 'Notifications',
    tools: 'Help panel',
    toolsToggle: 'Open help panel',
    toolsClose: 'Close help panel'
};
const Content = () => {
  const [data,setData] = useState([])
  const params = useParams();
  
  const [activeItem,setActiveItem] = useState({
        id:null,
        nickname:'',
        email:'',
        oldnickname : ''
      })
  const [visibleSuccessAlert,setVisibleSuccessAlert] = useState(false) 
  const {getAccessTokenSilently} = useAuth0();
  const { backendURL } = getConfig();
  
  
    useEffect(() => {
      const fetchData = async () => {
        
        const token = await getAccessTokenSilently()
        
      fetch(`${backendURL}/get_user/${params.id}`,
      {
        headers : {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json',
      }}
      )
   
      .then(response => {

        return response.json()

      })

      .then(data => {
        setActiveItem({...activeItem,id:data.user_id,nickname:data.nickname,email:data.email,oldnickname:data.nickname})
        setData(data)

      })
    }
    if(!activeItem || params.id !== activeItem.id)
    {
    fetchData()
  }
  },[activeItem,params.id,getAccessTokenSilently,backendURL])
  console.log(data.nickname)
  const updateUser = async () =>{
        try{
          const token = await getAccessTokenSilently()
        const response = await fetch(`${backendURL}/update/${params.id}`,{
            method :'PUT',
            headers : {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({"nickname":activeItem.nickname,"oldnickname":activeItem.oldnickname})
        })        
        
        console.log(response.status)
        setVisibleSuccessAlert(true)
        setTimeout(()=>{
          setVisibleSuccessAlert(false)
      },10000)

      } catch (error) {
        console.log("Error :", error)
      }
    }
    
    
    const handleSubmit = (e) =>{
        e.preventDefault()
        updateUser()
        
    }

    let navigate = useNavigate();
    const handleDelete = async ()=>{

      const token = await getAccessTokenSilently()
      fetch(`${backendURL}/delete/${params.id}`, 
      {method:'DELETE',
      headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
              }
             })
   
      .then(response => {

        return response.json()     

      }).then(
        navigate('/user-list', { replace: true })

      )       
   }
  
return(
    <div>
    <form onSubmit={handleSubmit}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
          <SpaceBetween direction="vertical" size="xs">
            <Link to={"/user-list"}>
            <Button formAction="none">
              Cancel
            </Button>
            </Link>
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="xs">
            <Button variant="primary" type="submit">Update</Button>
            </SpaceBetween>
          </SpaceBetween>

        }
        
      >
      <Container
          header={
            <Header variant="h2"
              
            actions={
            <SpaceBetween direction="horizontal"
              alignItems="end" size="xs"
            >
             <Button onClick={handleDelete}  variant="inline-link"> Delete User</Button>
              
            </SpaceBetween>
            }>
            Update User Information
            </Header>
            }
        >
           
        <div>
          {visibleSuccessAlert && (
                        <Alert
                        onDismiss={() => setVisibleSuccessAlert(false)}
                        visible={visibleSuccessAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="success"
                        header="Details Updated"
                    >
                        Your Nickname has been updated.
                    </Alert>
                    )}  
            
            <SpaceBetween direction="vertical" size="l">
            <FormField label="Email">
              <Input value={activeItem.email} disabled={true}/>
            </FormField>
            <FormField label="Nickname">
              <Input inputMode="text" name="nickname"  onChange={({detail})=>setActiveItem({...activeItem,nickname:detail.value})} value={activeItem.nickname}/>
            </FormField>
            <FormField label="User ID">
              <Input value={activeItem.id} disabled={true}/>
            </FormField>
            </SpaceBetween>
           
          </div>
        </Container>
      </Form>
    </form>
    </div>
  )
}

function App() {
  const [setNavigationOpen, handleToolsChange, navigationOpen, toolsOpen] = useState(true);

  return (
      
              <>
                 <div>
                  <NewNavigation />
                  </div>

                  <AppLayout
                      content={<Content/>}
                      headerSelector='#navbar'
                      navigation={<ServiceNavigation/>}
                      navigationOpen={navigationOpen}
                      onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                      onToolsChange={({detail2}) => handleToolsChange(detail2.open)}
                      tools={<HelpNavigation/>}
                      toolsOpen={toolsOpen}
                      ariaLabels={appLayoutLabels}
                  />

              </>
          

  );
}

export default App;