import React, {useState, useEffect} from 'react';
import "@cloudscape-design/global-styles/index.css"
import './App.css';
import Table from "@cloudscape-design/components/table";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import AppLayout from "@cloudscape-design/components/app-layout";
import { Link} from "react-router-dom";
import HelpNavigation from './HelpNavigation';
import ServiceNavigation from './ServiceNavigation';
import NewNavigation from './NewNavigation';
import Pagination from "@cloudscape-design/components/pagination";
import Box from "@cloudscape-design/components/box";
import {useAuth0} from '@auth0/auth0-react'
import { getConfig} from "./config";


const appLayoutLabels = {
    navigation: process.env.navigation,
    navigationToggle: process.env.navigationToggle,
    navigationClose: process.env.navigationClose,
    notifications: process.env.notifications,
    tools: process.env.tools,
    toolsToggle: process.env.toolsToggle,
    toolsClose: process.env.toolsClose
};

const Content = () => {
  const [data,setData] = useState([])
  const [filteringText,setFilteringText] = useState('')
  const [filteredData,setFilteredData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  const {getAccessTokenSilently} = useAuth0();
  const { backendURL } = getConfig();

  useEffect( () => {
    const fetchData = async () => {

    const token = await getAccessTokenSilently()
    
    fetch(`${backendURL}/users`,
    { 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        }
      })
   
      .then(response => {

        return response.json()

      })

      .then(data => {

        setData(data)
        setFilteredData(data)
        

      })
    
  }
    fetchData()
  },[getAccessTokenSilently,backendURL])


useEffect(() => {
      const filtered = data.filter(user => user.nickname.toLowerCase().includes(filteringText.toLowerCase()) || user.email.toLowerCase().includes(filteringText.toLowerCase()))
      setFilteredData(filtered);
      setCurrentPage(1);
    
  }, [filteringText, data]);
  
  const [dataPerPage] = useState(10);  
  const lastIndex = currentPage * dataPerPage;
  const firstIndex = lastIndex -  dataPerPage;
  const currentData = filteredData?.slice(firstIndex,lastIndex);
  let totalPages = Math.ceil(filteredData.length / dataPerPage);
  const changePageNo = (number) => {
    setCurrentPage(number);
  }

  
  return (
    <>   
    <Table
     
      columnDefinitions={[
       { id: "type", 
          header: "Nickname", 
          cell: e => e.nickname
          
        },
        {
          id: "value",
          header: "Email address",
          cell: e => e.email
        },
        {
          id: "variable",
          header: "User name",
          cell: e => e.user_id,
          isRowHeader: true
        }
      ]}
      
      columnDisplay={[
        
        { id: "variable", visible: true },
        { id: "value", visible: true },
         { id: "type", visible: true }
                
      ]}

      items={ currentData.map(element => (

        {
          user_id: <Link to={`/get-user/${element.user_id}`}>{element.user_id}</Link>,
          email: `${element.email}`,
          nickname: `${element.nickname}`
      
        }
        
       ))
      }
       
    pagination={
      <Pagination
      currentPageIndex={currentPage}
      onChange={({ detail }) =>
        changePageNo(detail.currentPageIndex)
      }
      pagesCount={totalPages}
    />
    } 
      
    loadingText="Loading resources"
    
    resizableColumns
    empty={
      <Box
        margin={{ vertical: "xs" }}
        textAlign="center"
        color="inherit"
      >
        <SpaceBetween size="m">
          <b>No User Found</b>
         
        </SpaceBetween>
      </Box>
    }

    filter={
      <TextFilter
      filteringText={filteringText}
      name="searchtext"
      filteringPlaceholder="Search Users by Nickname"
      filteringAriaLabel="Filter instances"
      onChange={({ detail }) =>
        setFilteringText(detail.filteringText)

      }
      />
      }
      
      header={
        <Header
          
          actions={
            <SpaceBetween
              direction="horizontal"
              size="xs"
            >
           
            <Link to= {`/register?data=${encodeURIComponent(JSON.stringify(data))}`}>
              <Button variant="primary">
                Create User
              </Button> 
            </Link>
            </SpaceBetween>
          }
        >
          Shelter Users
        </Header>
      }
  />
 
    </>
  );
}

function App() {
  const [setNavigationOpen, handleToolsChange, navigationOpen, toolsOpen] = useState(true);

  return (
      
              <>
                 <div>
                  <NewNavigation />
                  </div>      

                  <AppLayout
                      content={<Content/>}
                      headerSelector='#navbar'
                      navigation={<ServiceNavigation/>}
                      navigationOpen={navigationOpen}
                      onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
                      onToolsChange={({detail2}) => handleToolsChange(detail2.open)}
                      tools={<HelpNavigation/>}
                      toolsOpen={toolsOpen}
                      ariaLabels={appLayoutLabels}
                  />

              </>
          
  );
}

export default App;